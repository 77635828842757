import { WithShadow } from "./";

import { ITile } from "../../interfaces/sharedComponentInterfaces";

const Tile = (props: ITile) => {
  const { children, pulse, onClick, size, tooltipId, tooltipText } = props;

  let height = "h-[350px]";
  if (size === "small") height = "h-48";

  let width = "w-[300px]";
  if (size === "small") width = "w-48";

  return (
    <WithShadow customClasses={`flex-col items-center ${height} ${width}`} onClick={onClick} pulse={pulse} testId="tile" tooltipId={tooltipId} tooltipText={tooltipText}>
      {children}
    </WithShadow>
  );
};

export default Tile;
