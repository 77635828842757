import { useLayoutEffect, useState } from "react";

import { useBaseStore } from "../store";

import { fetchJoinedSectorBundle } from "../services/objects/joinedSectorBundles.service";
import { handleFetchObjectResponse } from "../services/responseDataHandler";
import { IJoinedSectorBundleObject } from "../interfaces/joinedSectorBundleInterfaces";

const useJoinedSectorBundle = (id?: number) => {
  const refresh = useBaseStore((state) => state.refresh);
  const setGlobalLoader = useBaseStore((state) => state.setGlobalLoader);
  const [joinedSectorBundle, setJoinedSectorBundle] = useState<IJoinedSectorBundleObject | undefined>(undefined);

  const fetchObject = () => {
    if (!id || id === -1) return;

    fetchJoinedSectorBundle({ id: id }).then((response) => {
      handleFetchObjectResponse({ response: response, setObject: setJoinedSectorBundle, setGlobalLoader: setGlobalLoader });
    });
  };

  useLayoutEffect(() => {
    fetchObject();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, refresh]);

  return { joinedSectorBundle, setJoinedSectorBundle };
};

export default useJoinedSectorBundle;
