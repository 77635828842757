import { Fragment, lazy, Suspense } from "react";
import { Navigate, Routes, Route } from "react-router-dom";

import { Loader } from "../../shared/components";

import { useLocalStorage } from "../../hooks";

import * as routes from "../../shared/routes";

const AdjustmentForm = lazy(() => import("../Invoices/AdjustmentForm"));
const AdminTasks = lazy(() => import("../AdminTasks/AdminTasks"));
const CarnetOrder = lazy(() => import("../CarnetOrder/CarnetOrder"));
const CarnetOrders = lazy(() => import("../CarnetOrders/CarnetOrders"));
const CarnetTemplates = lazy(() => import("../CarnetTemplates/CarnetTemplates"));
const Carnets = lazy(() => import("../Carnets/Carnets"));
const Cashiers = lazy(() => import("../Cashiers/Cashiers"));
const Dashboard = lazy(() => import("../Dashboard/Dashboard"));
const Event = lazy(() => import("../Event/Event"));
const EventTemplates = lazy(() => import("../EventTemplates/EventTemplates"));
const Events = lazy(() => import("../Events/Events"));
const FaqContacts = lazy(() => import("../FaqContacts/FaqContacts"));
const InvoiceForm = lazy(() => import("../Invoices/InvoiceForm"));
const Invoices = lazy(() => import("../Invoices/Invoices"));
const JoinedSectorBundle = lazy(() => import("../JoinedSectorBundle/JoinedSectorBundle"));
const JoinedSectorBundles = lazy(() => import("../JoinedSectorBundles/JoinedSectorBundles"));
const OldInvoices = lazy(() => import("../OldInvoices/OldInvoices"));
const NotFound = lazy(() => import("../NotFound/NotFound"));
const Order = lazy(() => import("../Order/Order"));
const Orders = lazy(() => import("../Orders/Orders"));
const PrepaidCarnetTemplates = lazy(() => import("../PrepaidCarnetTemplates/PrepaidCarnetTemplates"));
const ProductOrder = lazy(() => import("../ProductOrder/ProductOrder"));
const ProductOrders = lazy(() => import("../ProductOrders/ProductOrders"));
const Products = lazy(() => import("../Products/Products"));
const Reports = lazy(() => import("../Reports/Reports"));
const SecondDisplay = lazy(() => import("../SecondDisplay/SecondDisplay"));
const Settings = lazy(() => import("../Settings/Settings"));
const Stands = lazy(() => import("../Stands/Stands"));
const Tickets = lazy(() => import("../Tickets/Tickets"));

const AuthenticatedRoutes = () => {
  const [currentCashier] = useLocalStorage("current_cashier", null);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* Main routes */}
        <Route path={routes.ADJUSTMENT_FORM} element={<AdjustmentForm />} />
        <Route path={routes.ADMIN_TASKS} element={<AdminTasks />} />
        <Route path={routes.CARNET_ORDER} element={<CarnetOrder />} />
        <Route path={routes.CARNET_ORDERS} element={<CarnetOrders />} />
        <Route path={routes.CARNET_TEMPLATES} element={<CarnetTemplates />} />
        <Route path={routes.CARNETS} element={<Carnets />} />
        <Route path={routes.DASHBOARD} element={<Dashboard />} />
        <Route path="/" element={<Dashboard />} />
        <Route path={routes.EVENT} element={<Event />} />
        <Route path={routes.EVENTS} element={<Events />} />
        <Route path={routes.EVENT_TEMPLATES} element={<EventTemplates />} />
        <Route path={routes.FAQ_CONTACTS} element={<FaqContacts />} />
        <Route path={routes.INVOICE_FORM_ROUTE} element={<InvoiceForm />} />
        <Route path={routes.INVOICES} element={<Invoices />} />
        <Route path={routes.JOINED_SECTOR_BUNDLE} element={<JoinedSectorBundle />} />
        <Route path={routes.JOINED_SECTOR_BUNDLES} element={<JoinedSectorBundles />} />
        <Route path={routes.OLD_INVOICES} element={<OldInvoices />} />
        <Route path={routes.ORDER} element={<Order />} />
        <Route path={routes.ORDERS} element={<Orders />} />
        <Route path={routes.PREPAID_CARNET_TEMPLATES} element={<PrepaidCarnetTemplates />} />
        <Route path={routes.PRODUCT_ORDER} element={<ProductOrder />} />
        <Route path={routes.PRODUCT_ORDERS} element={<ProductOrders />} />
        <Route path={routes.PRODUCTS} element={<Products />} />
        <Route path={routes.SETTINGS} element={<Settings />} />
        <Route path={routes.TICKETS} element={<Tickets />} />
        {currentCashier?.type === "manager" && (
          <Fragment>
            <Route path={routes.CASHIERS} element={<Cashiers />} />
            <Route path={routes.REPORTS} element={<Reports />} />
            <Route path={routes.STANDS} element={<Stands />} />
          </Fragment>
        )}

        {/* Second display routes */}
        <Route path={routes.SECOND_DISPLAY} element={<SecondDisplay />} />

        {/* Fallback routes */}
        <Route path='/' element={<Dashboard />} />
        <Route path={routes.NOT_FOUND} element={<NotFound />} />
        <Route path="*" element={<Navigate to={routes.NOT_FOUND} />} />
      </Routes>
    </Suspense>
  );
};

export default AuthenticatedRoutes;
