import { BASE_URL } from "../axiosCashier";

export function imageUrl(url: string) {
  return `${BASE_URL}${url}`;
}

export function currencyFormat(amount: string | number, currency: "PLN" | "EUR" | "USD" | "CZK" = "PLN") {
  const stringAmount = String(amount);
  const parsedAmount = parseFloat(stringAmount).toFixed(2);
  return `${parsedAmount.replace(".", ",")} ${currency}`;
}

export function dateFormat(date: string) {
  const dateObject = new Date(date);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");

  return `${day}.${month}.${year}`;
}

export function dateTimeFormat(date: string) {
  const dateObject = new Date(date);

  const hours = String(dateObject.getHours()).padStart(2, "0");
  const minutes = String(dateObject.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes} ${dateFormat(date)}`;
}

export function dateFormatWithTime(date: string | Date, forPrinter: boolean = false) {
  const dateObject = new Date(date);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");

  const hours = String(dateObject.getHours()).padStart(2, "0");
  const minutes = String(dateObject.getMinutes()).padStart(2, "0");
  const seconds = String(dateObject.getSeconds()).padStart(2, "0");

  return forPrinter ? `${day}.${month}.${year}, ${hours}:${minutes}` : `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}

export const handlePostalInput = (e: React.FormEvent<HTMLInputElement>) => {
  let inputValue = e.currentTarget.value;
  // Remove all non-numeric characters
  inputValue = inputValue.replace(/\D/g, "");
  // Limit to five characters
  inputValue = inputValue.slice(0, 5);
  // Format as 12-123
  if (inputValue.length > 2) {
    inputValue = `${inputValue.slice(0, 2)}-${inputValue.slice(2)}`;
  }
  e.currentTarget.value = inputValue;
};

export const handleMoneyInput = (e: React.FormEvent<HTMLInputElement>) => {
  let inputValue = e.currentTarget.value;
  // Remove all non-numeric characters
  inputValue = inputValue.replace(/[^0-9.,]/g, "");
  // Replace , with . for decimal point
  inputValue = inputValue.replace(/,/g, ".");
  // Ensure only one decimal point is present
  inputValue = inputValue.replace(/(\..*)\./g, "$1");
  // Limit to two decimal places
  const parts = inputValue.split(".");
  if (parts.length > 1) {
    inputValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
  }
  e.currentTarget.value = inputValue;
};

export const handlePhoneInput = (e: React.FormEvent<HTMLInputElement>) => {
  let inputValue = e.currentTarget.value;
  inputValue = inputValue.replace(/[^\d.+\s()-]/g, "");
  inputValue = inputValue.replace(/\s/g, "");
  e.currentTarget.value = inputValue;
};

export const limitStringToPaperWidth = (string: string, paperWidth: 55 | 80, centerText?: 0 | 1, fontSize?: 0 | 1) => {
  const centerTextToUse = centerText || 0;
  const fontSizeToUse = fontSize || 0;
  let maxCharacters = 42;
  switch (paperWidth) {
    case 55:
      maxCharacters = fontSizeToUse === 1 ? 21 : 42;
      break;
    case 80:
      maxCharacters = fontSizeToUse === 1 ? 28 : 56;
      break;
  }

  if (string.length <= maxCharacters) return [{ type: "text_line", text: string, centerText: centerTextToUse, fontSize: fontSizeToUse }];

  let result = [];
  for (let i = 0; i < string.length; i += maxCharacters) {
    let textChunk = string.slice(i, i + maxCharacters);
    result.push({ type: "text_line", text: textChunk, centerText: centerTextToUse, fontSize: fontSizeToUse });
  }
  return result;
};

export const numberInWords = (number: number) => {
  const ones = ["zero", "jeden", "dwa", "trzy", "cztery", "pięć", "sześć", "siedem", "osiem", "dziewięć"];
  const teens = ["dziesięć", "jedenaście", "dwanaście", "trzynaście", "czternaście", "piętnaście", "szesnaście", "siedemnaście", "osiemnaście", "dziewiętnaście"];
  const tens = ["", "", "dwadzieścia", "trzydzieści", "czterdzieści", "pięćdziesiąt", "sześćdziesiąt", "siedemdziesiąt", "osiemdziesiąt", "dziewięćdziesiąt"];
  const hundreds = ["", "sto", "dwieście", "trzysta", "czterysta", "pięćset", "sześćset", "siedemset", "osiemset", "dziewięćset"];
  const thousands = ["", "tysiąc", "tysiące", "tysięcy"];

  let integerPart = Math.floor(number);
  let decimalPart = Math.round((number - integerPart) * 100);

  const integerInWords = (number: number) => {
    if (number === 0) {
      return ones[0];
    }
    let result = "";
    const onesDigit = number % 10;
    const tensDigit = Math.floor((number % 100) / 10);
    const hundredsDigit = Math.floor((number % 1000) / 100);
    const thousandsDigit = Math.floor(number / 1000);
    if (thousandsDigit > 0) result += thousands[thousandsDigit];
    if (hundredsDigit > 0) result += " " + hundreds[hundredsDigit];
    if (tensDigit === 1) {
      result += " " + teens[onesDigit];
    } else {
      result += " " + tens[tensDigit] + " " + ones[onesDigit];
    }
    return result.trim();
  };

  return integerInWords(integerPart) + ` PLN, ${decimalPart}/100`;
};

export const clampToRange = (value: number, minRange: number, maxRange: number) => {
  // when parsing '' to number, it returns 0 and we want to return 0 in this case
  if (value === 0) return 0;

  return Math.min(Math.max(value, minRange), maxRange);
};

export function addDays(date: string, days: number | undefined) {
  if (!days) return null;

  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const removeHtmlTagsAndFormat = (input: string) => {
  const withoutTags = input.replace(/(<([^>]+)>)/gi, "").replace(/&([^;]+);/gi, "");
  const withNewLines = withoutTags.replace(/<\/[^>]+>/g, "\n");
  return withNewLines;
};

export const stripPolishCharacters = (inputString: string, polishCharacters: boolean) => {
  if (polishCharacters) return inputString;

  const polishChars = "ąćęłńóśźżĄĆĘŁŃÓŚŹŻ";
  const englishChars = "acelnoszzACELNOSZZ";
  const charMap: { [key: string]: string } = {};

  for (let i = 0; i < polishChars.length; i++) {
    charMap[polishChars[i]] = englishChars[i];
  }

  return inputString.replace(/[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g, (char) => charMap[char] || char);
};
