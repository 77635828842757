import { IfiscalPrinterSettings, IticketPrinterSettings } from "./interfaces/printerInterfaces";
import { IterminalSettings } from "./interfaces/terminalInterfaces";

export const APP_VERSIONING: { number: string; date: string; changes: number }[] = [
  { number: "1.0.5", date: "2025-02-10", changes: 10 },
  { number: "1.0.4", date: "2025-01-29", changes: 6 },
  { number: "1.0.3", date: "2024-06-17", changes: 7 },
  { number: "1.0.2", date: "2024-04-30", changes: 5 },
  { number: "1.0.1", date: "2024-04-08", changes: 7 },
  { number: "1.0.0", date: "2024-02-08", changes: 7 },
  { number: "0.9.9", date: "2024-01-22", changes: 8 },
  { number: "0.9.8", date: "2023-12-30", changes: 3 },
  { number: "0.9.7", date: "2023-12-07", changes: 2 },
  { number: "0.9.6", date: "2023-12-05", changes: 3 },
  { number: "0.9.5", date: "2023-11-29", changes: 3 },
  { number: "0.9.4", date: "2023-11-20", changes: 2 },
  { number: "0.9.3", date: "2023-11-17", changes: 4 },
  { number: "0.9.2", date: "2023-11-16", changes: 2 },
  { number: "0.9.1", date: "2023-11-15", changes: 2 },
  { number: "0.9.0", date: "2023-11-14", changes: 1 }
];

export const FISCAL_NON_FISCAL_PRINT_SLEEP = 4500;
export const IMPORT_FILE_TYPES = ["text/plain", "text/csv"];
export const TERMINAL_PAYMENT_SLEEP = 4000;

// complex
export const INITIAL_FISCAL_PRINTER_SETTINGS: IfiscalPrinterSettings = {
  fiscalPrint: false,
  fiscalCashRegister: false,
  fiscalPrintNip: false,
  fiscalPrinterTicketPrint: false,
  paperWidth: 55,
  port: {
    name: "COM3"
  },
  protocol: "novitus",
  vatRates: { A: 23, B: 8, C: 5, D: 0, E: -1 }
};
export const INITIAL_POS_SETTINGS: IposSettings = {
  minutes_after_pos_sale_end: 180,
  vat_23: "A",
  vat_8: "B",
  vat_5: "C",
  vat_0: "D",
  vat_zw: "E"
};
export const INITIAL_TERMINAL_SETTINGS: IterminalSettings = {
  terminalPayment: false,
  // connection_type: 'TCP',
  port:{
    name: "COM8"
  },
  protocol: "it_card"
};
export const INITIAL_TICKET_PRINTER_SETTINGS: IticketPrinterSettings = {
  ticketPrint: false,
  printType: "ticketPrinter",
  printerName: "",
  printerModel: "",
  polishCharacters: true
};
export const TEST_PRINT_ORDER_DATA = {
  barcode: "123456789",
  client_id: 1,
  confirmed_at: "",
  created_at: "",
  dotpay_desc: "Gotówka",
  event_id: 1,
  event_address: "Ulica Testowa 1",
  event_formatted_store_date: "",
  event_title: "Testowe wydarzenie",
  hall_name: "Sala Testowa",
  id: 1,
  non_fiscal: false,
  picture_url: "",
  reservations_count: 0,
  tickets: [
    {
      barcode: "123456789",
      complained_at: null,
      complaint: null,
      event_id: 1,
      id: 1,
      order_id: 1,
      price: "100.00",
      printed: true,
      non_fiscal: false,
      state: "ready",
      spot_desc: "Rz. 1, M. 1",
      ticket_typ: "Normalny",
      total_price: "100.00",
      transmission_type: null,
      validated_at: null,
      holder_informations: {}
    },
    {
      barcode: "987654321",
      complained_at: null,
      complaint: null,
      event_id: 1,
      id: 2,
      order_id: 1,
      price: "80.00",
      printed: true,
      non_fiscal: false,
      state: "ready",
      spot_desc: "Rz. 1, M. 2",
      ticket_typ: "Ulgowy",
      total_price: "80.00",
      transmission_type: null,
      validated_at: null,
      holder_informations: {}
    }
  ],
  tickets_count: 2,
  total_payable_amount: "180.00",
  user_name: "Nazwa organizatora",
  custom_printer_messages: {
    line1: "Testowa linia 1",
    line2: "Testowa linia 2",
    line3: "Testowa linia 3",
    line4: "Testowa linia 4",
    line5: "Testowa linia 5"
  }
};

// Interfaces
interface IposSettings {
  minutes_after_pos_sale_end: number;
  vat_23: "A" | "B" | "C" | "D" | "E";
  vat_8: "A" | "B" | "C" | "D" | "E";
  vat_5: "A" | "B" | "C" | "D" | "E";
  vat_0: "A" | "B" | "C" | "D" | "E";
  vat_zw: "A" | "B" | "C" | "D" | "E";
}
