import { baseInstance } from "../../axiosCashier";
import {
  IconfirmOrder,
  IconfirmWithCarnetOrder,
  IcreateOrder,
  IdestroyOrder,
  IfetchOrder,
  IfetchOrderForPosNewInvoice,
  IfetchOrders,
  IfetchOrdersForPosNewInvoice,
  IfillHolderInformationsOrderReservation,
  IreservationConfirmOrder,
  IreservationConvertToOnlineOrder,
  IupdateFormOrder
} from "../../interfaces/orderInterfaces";
import { fetchQueryParams } from "../queryParamsHandler";

const BASE_URL = "/orders";

export const confirmOrder = (params: IconfirmOrder) => {
  const queryParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}/confirm.json`, queryParams);
};

export const confirmWithCarnetOrder = (params: IconfirmWithCarnetOrder) => {
  return baseInstance.patch(`${BASE_URL}/${params.id}/confirm_with_carnet.json?barcode=${params.barcode}`);
};

export const createOrder = (params: IcreateOrder) => {
  return baseInstance.post(`${BASE_URL}.json`, params);
};

export const destroyOrder = (params: IdestroyOrder) => {
  return baseInstance.delete(`${BASE_URL}/${params.id}.json`);
};

export const fetchOrder = (params: IfetchOrder) => {
  const queryParams = fetchQueryParams(params, ["id"]);
  return baseInstance.get(`${BASE_URL}/${params.id}.json${queryParams}`);
};

export const fetchOrderForPosNewInvoice = (params: IfetchOrderForPosNewInvoice) => {
  return baseInstance.get(`${BASE_URL}/${params.id}/show_for_pos_new_invoice.json`);
};

export const fetchOrders = (params: IfetchOrders) => {
  const queryParams = fetchQueryParams(params, ["cooperator_id", "event_id"]);
  return baseInstance.get(`${BASE_URL}.json${queryParams}`);
};

export const fetchOrdersForPosNewInvoice = (params: IfetchOrdersForPosNewInvoice) => {
  const queryParams = fetchQueryParams(params);
  return baseInstance.get(`${BASE_URL}/index_for_pos_new_invoice.json${queryParams}`);
};

export const fillHolderInformationsOrderReservation = (params: IfillHolderInformationsOrderReservation) => {
  const patchParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && key !== "reservation_id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}/fill_holder_informations.json?reservation_id=${params.reservation_id}`, patchParams);
};

export const reservationConfirmOrder = (params: IreservationConfirmOrder) => {
  const queryParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}/reservation_confirm.json`, queryParams);
};

export const reservationConvertToOnlineOrder = (params: IreservationConvertToOnlineOrder) => {
  const queryParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}/reservation_convert_to_online.json`, queryParams);
};

export const updateFormOrder = (params: IupdateFormOrder) => {
  const updateFormParams = Object.fromEntries(Object.entries(params).filter(([key, value]) => key !== "id" && value !== ""));
  return baseInstance.patch(`${BASE_URL}/${params.id}/update_form.json`, updateFormParams);
};
