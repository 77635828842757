import { baseInstance } from "../../axiosCashier";
import { IfetchJoinedSectorBundle, IfetchJoinedSectorBundles, IjoinedSectorBundleCreateMultiOrder } from "../../interfaces/joinedSectorBundleInterfaces";
import { fetchQueryParams } from "../queryParamsHandler";

const BASE_URL = "/joined_sector_bundles";

export const fetchJoinedSectorBundle = (params: IfetchJoinedSectorBundle) => {
  return baseInstance.get(`${BASE_URL}/${params.id}.json`);
};

export const fetchJoinedSectorBundles = (params: IfetchJoinedSectorBundles) => {
  const queryParams = fetchQueryParams(params, ["cooperator_id"]);
  return baseInstance.get(`${BASE_URL}.json${queryParams}`);
};

export const joinedSectorBundleCreateMultiOrder = (params: IjoinedSectorBundleCreateMultiOrder) => {
  const { id, ...restParams } = params;
  return baseInstance.post(`${BASE_URL}/${params.id}/create_multi_order.json`, restParams);
};